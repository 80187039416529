// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-job-post-tsx": () => import("/codebuild/output/src935475770/src/src/templates/job-post.tsx" /* webpackChunkName: "component---src-templates-job-post-tsx" */),
  "component---src-templates-index-page-tsx": () => import("/codebuild/output/src935475770/src/src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-interview-post-tsx": () => import("/codebuild/output/src935475770/src/src/templates/interview-post.tsx" /* webpackChunkName: "component---src-templates-interview-post-tsx" */),
  "component---src-templates-service-post-tsx": () => import("/codebuild/output/src935475770/src/src/templates/service-post.tsx" /* webpackChunkName: "component---src-templates-service-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("/codebuild/output/src935475770/src/src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src935475770/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-index-tsx": () => import("/codebuild/output/src935475770/src/src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */)
}

