module.exports = [{
      plugin: require('/codebuild/output/src935475770/src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/codebuild/output/src935475770/src/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/codebuild/output/src935475770/src/src/cms/cms.ts"},
    },{
      plugin: require('/codebuild/output/src935475770/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false},
    },{
      plugin: require('/codebuild/output/src935475770/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
